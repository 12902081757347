
.icon-letter {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__btn {
        position: fixed;
        width: 60px;
        height: 60px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        bottom: 14px;
        z-index: 10;
        &:hover, :active {
            opacity:2.8;
        }
    } 
}

@media (max-width: 1240px) {
    .icon-letter__btn {
            position: fixed;
            width: 60px;
            height: 60px;
            right: 3px;
    }
}