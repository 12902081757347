@import "./variables";
@import "./mixins";

@font-face {
  font-family: 'Roboto400';
  font-style: normal;
  src: url('../fonts/roboto400.woff2') format('woff2'),
       url('../fonts/roboto400.woff') format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto700';
  font-style: normal;
  src: url('../fonts/roboto700.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Inter400';
  font-style: normal;
  src: url('../fonts/inter400.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Inter500';
  font-style: normal;
  src: url('../fonts/inter500.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Inter600';
  font-style: normal;
  src: url('../fonts/inter600.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Inter700';
  font-style: normal;
  src: url('../fonts/inter700.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  src: url('../fonts/raleway700.woff2') format('woff2'),;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  src: url('../fonts/ptsans400.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  src: url('../fonts/opensans600.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}
:root {
    --swiper-theme-color: rgba(255, 255, 255, 0);
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto400', Arial, Helvetica, Courier, Tahoma, Joanna, GeezaPro, NotoNastaliqUrdu, Ayuthaya, Kailasa, sans-serif;
    // Firefox 
    scrollbar-width: thin;
    scrollbar-color: #207945 #F4F4F4;
    max-width: 100%;
}

/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #8b8b8b;
  }

body.modal-open {
    overflow: hidden;
}

html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
}
ul,
li,
dl {
    list-style-type: none;
    margin: 0;
    padding: 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

p {
    margin: 0;
    padding: 0;
}

.container {
    width: 1220px;
    margin: 0 auto;
    @media (max-width: 1240px) {
        width: 970px;
    }
    @media (max-width: 992px) {
        width: 720px;
    }
    @media (max-width: 767px) {
      width: 540px;
    }
    @media (max-width: 576px) {
        width: 100%;
        padding: 0 15px;
    }
  }

.fz_46 {
    border-bottom: 5px solid $main-color;
    position: relative;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $third-color;
    padding-bottom: 18px;
    font-weight: 400;
    @media (max-width: 992px) {
        font-size: 30px;
    }
    @media (max-width: 767px) {
        font-size: 25px
    }
    @media (max-width: 576px) {
        font-size: 25px;
        padding-bottom: 6px;
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 150px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-image: linear-gradient($main-color 50%, #bbb 51%),
      linear-gradient(#ddd, #ddd), linear-gradient(#ddd, #ddd),
      radial-gradient(ellipse at center, #aaa 25%, #aaa 26%, $main-color 50%, #0000 55%),
      radial-gradient(ellipse at center, #aaa 25%, #aaa 26%, $main-color 50%, #0000 55%),
      radial-gradient(ellipse at center, #aaa 25%, #aaa 26%, $main-color 50%, #0000 55%);
    background-position: 0 20px, 45px 0, 8px 6px, 55px 3px, 75px 3px, 95px 3px;
    background-size: 100% 4px, 1px 23px, 30px 8px, 15px 15px, 15px 15px, 15px 15px;
    position: relative;
    border-radius: 6%;
    animation: shake 3s ease-in-out infinite;
    transform-origin: 60px 180px;
    border: 1px solid $main-color;
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #fff;
        z-index: 99999999;
    }
  }
  
  .loader:before {
    content: "";
    position: absolute;
    left: 5px;
    top: 100%;
    width: 7px;
    height: 5px;
    background: $main-color;
    border-radius: 0 0 4px 4px;
    box-shadow: 102px 0 $main-color;
  }
  
.loader:after {
    content: "";
    position: absolute;
    width: 95px;
    height: 95px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 20px;
    background-color: #bbdefb;
    background-image: linear-gradient( to right, #0004 0%, #0004 49%, #0000 50%, #0000 100% ),
      linear-gradient(135deg, #64b5f6 50%, #607d8b 51%);
    background-size: 30px 100%, 90px 80px;
    border-radius: 50%;
    background-repeat: repeat, no-repeat;
    background-position: 0 0;
    box-sizing: border-box;
    border: 10px solid $main-color;
    box-shadow: 0 0 0 4px #999 inset, 0 0 6px 6px $main-color inset;
    animation: spin 3s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg)
    }
  
    50% {
      transform: rotate(360deg)
    }
  
    75% {
      transform: rotate(750deg)
    }
  
    100% {
      transform: rotate(1800deg)
    }
  }
  
  @keyframes shake {
    65%, 80%, 88%, 96% {
      transform: rotate(0.5deg)
    }
  
    50%, 75%, 84%, 92% {
      transform: rotate(-0.5deg)
    }
  
    0%, 50%, 100% {
      transform: rotate(0)
    }
  }
