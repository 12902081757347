@import '../../style/mixins';
@import '../../style/variables';

.cookie-consent {
    padding: 20px;
    border: 3px solid $main-color;
    width: 90%;
    max-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: max-content;
    gap: 20px;
    position: fixed;
    z-index: 10000;
    background: #FFFFFF;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    &__wrapper {
        position: relative;
    }
    &__text {
        padding: 0 ;
        font-family: 'Inter400';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: $third-color;
        &_link {
            color: $main-color;
            font-style: bold;
        }
    }
}
@media (max-width: 1280px) {
    .cookie-consent {
        width:90%;
    }
}

@media (max-width: 767px) {
    .cookie-consent {
        flex-direction: column;
        &__btn {
            width: 100%;
        }
    }
  }