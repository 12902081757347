@import '../../style/mixins';
@import '../../style/variables';

.form-callback {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__wrapper{
        position: relative;
        min-height: 496px;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        padding: 40px 50px;
        margin: 0 auto;
        &_infomodal {
            max-width: 750px;
            background: $bg-color;
        }
        &_main {
            max-width: 496px;
        }
        &_phone {
            padding-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap:10px;
            font-size: 16px;
            & img {
                height: 22px;
            }
            & span {
                font-family: 'Roboto400';
                font-size: 20px;
                font-weight: bold;
                background: transparent;
                border: none;
                cursor: pointer;
            }
        }
    }
    &__btn-close {
        position: absolute;
        top: 15px;
        font-size: 35px;
        line-height: 30px;
        right: 15px;
        background: transparent;
        border: 0;
        color: $main-color;
        font-weight:900;
        cursor: pointer;
    }
    &__subtitle {
        font-family: 'Roboto400';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: $main-color;
    }

    &__input-wrapper {
        position: relative;
        width: 100%;
    }

    &__privacy-policy {
        margin-left: 0;
        font-size: 12px;
        color: rgb(9, 9, 9, .7);
        font-family: 'Roboto400';
        font-style: normal;
        font-weight: 400;
        text-align: left;
        &__wrapper {
            margin-top: 18px;
        }
        &_google {
            width: 94%;
        }
        &_link {
            cursor: pointer;
            font-style: bold;
            color: $main-color;
        }
    }

    &__btn{
        width: 192px;
        height: 51px;
        background: #FFFFFF;
        background-color:$second-color;
        padding: 16px 0;
        cursor: pointer;
        font-family: 'Inter700';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: $third-color;
        border: none;
        margin-top: 16px;
        &_disable{
            opacity: 0.6;
        }
        &:hover {
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
            background-color: #b0e70cb9;
        }
        &:active {
            background-color:#87b303e8;
        }
    }

    &__modal{
        padding: 40px ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $bg-color;
        &-overlay {
            width: 100%;
            height: 100%; 
            background-color: rgba(0, 0, 0, 0.5); 
            position:fixed;
            z-index: 999;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
    }
}

#infomodal {
    min-height: 320px ;
}

.form-callback #callback_tel, #callback_zip-code, {
    width: 100%;
    height: 58px;
    border: 1px solid $main-color;
    font-size: 16px;
    border-radius: 0;
    outline: none;
    padding: 20px;
    margin-top: 24px;
}

.form-callback 
#callback_message {
    width: 100%;
    height: 90px;
    border: 1px solid $main-color;
    resize: none;
    border-radius: 0;
    font-size: 16px;
    padding: 20px;
    outline: none;
    margin-top: 24px;
}

.form-callback
#callback_tel::placeholder , 
#callback_zip-code::placeholder,
#callback_message::placeholder {
    font-family: 'Roboto400';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: $placeholder-text-color;
}

.error-form_callback {
    display: block;
    position: absolute;
    color:red;
    font-size: 12px;
    left: 20px;
    bottom: -18px;
    &_message {
        bottom: -14px;
    }
}

.visually-hidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
  }

@media (max-width: 767px) {
    .form-callback {
        &__btn{
            height: 44px;
            font-size: 16px;
            line-height: 10px;
            margin-top: 20px;
        }
        &__btn-close {
            font-size: 27px;
            line-height: 21px;
            right: 15px;
            top: 15px;
        }
        &__wrapper {
            min-height: 450px;
        }
    
        #callback_tel, #callback_zip-code, {
            height: 44px;
            border: 1px solid $main-color;
            font-size: 16px;
            border-radius: 0;
            outline: none;
            padding: 20px;
            margin-top: 16px;
        }
        
        #callback_message {
            height: 80px;
            font-size: 16px;
            padding: 20px;
            outline: none;
            margin-top: 16px;
        }
    
        .error-form_callback {
            display: block;
            position: absolute;
            color:red;
            font-size: 11px;
            left: 20px;
            bottom: -15px;
            &_message {
                bottom: -11px;
            }
        }
    }
}

@media (max-width: 500px) {
    .form-callback{
        &__wrapper {
            min-height: 420px;
        }
        &__privacy-policy_google {
        width: 100%;
        line-height: 12px;
    }
    }
}

@media (max-width: 450px) {

    .form-callback {
        &__wrapper{
            padding: 20px 18px 20px;
        }
        .form-callback__privacy-policy, .form-callback__privacy-policy_google {
            line-height: 12px;
            font-size: 10px;
        }
        &__subtitle {
            font-size: 20px;
        }
        &__btn-close {
            top: 10px;
            right: 10px;
            line-height: 20px;
        }
    }
    
}